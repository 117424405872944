import { NavLink } from "react-router-dom";
import Banners from "../banner/Banners";
import './HomePage.scss'

function HomePage() {
    return (
        <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article className="post-10 page type-page status-publish ast-article-single" id="post-10"
                itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                <header className="entry-header ast-no-title ast-header-without-markup">
                </header>
                <div className="entry-content clear" itemprop="text">
                  <div data-elementor-type="wp-post" data-elementor-id="10" className="elementor elementor-10">
                    <section
                      className="home-banner-section background-section-img elementor-section elementor-top-section elementor-element elementor-element-4285018 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="4285018" data-element_type="section" style={{backgroundImage:"url('images/painting-exhibition.jpg')"}}>
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0c65b18"
                          data-id="0c65b18" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-1680dea elementor-widget elementor-widget-uael-infobox"
                              data-id="1680dea" data-element_type="widget" data-widget_type="uael-infobox.default">
                              <div className="elementor-widget-container">
                                <div
                                  className="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-button">
                                  <div className="uael-infobox-left-right-wrap">
                                    <div className="uael-infobox-content">
                                      <div className="uael-infobox-title-wrap">
                                        <h4 className="uael-infobox-title-prefix elementor-inline-editing"
                                          data-elementor-setting-key="infobox_title_prefix"
                                          data-elementor-inline-editing-toolbar="basic">All My Artworks Are</h4>
                                        <h1 className="uael-infobox-title elementor-inline-editing"
                                          data-elementor-setting-key="infobox_title"
                                          data-elementor-inline-editing-toolbar="basic">Inspired by Nature</h1>
                                      </div>
                                      <div className="uael-infobox-text-wrap">
                                        <div className="uael-infobox-text elementor-inline-editing"
                                          data-elementor-setting-key="infobox_description"
                                          data-elementor-inline-editing-toolbar="advanced">
                                          Vestibulum mollis nisi at mauris accumsan dapibus. In dictum dui quis sem
                                          ullamcorper, eu convallis massa ultricies. Nulla viverra rutrum dolor, at varius
                                          lectus ultrices id. Mauris ipsum eros, pulvinar eu elit luctus, cursus mattis
                                          libero. </div>
                                        <div className="uael-button-wrapper elementor-button-wrapper">
                                          <a href="#" className="elementor-button-link  elementor-button elementor-size-sm">
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-align-icon-right elementor-button-icon">
                                              </span>
                                              <span className="elementor-button-text elementor-inline-editing"
                                                data-elementor-setting-key="infobox_button_text"
                                                data-elementor-inline-editing-toolbar="none">See Gallery</span>
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f17ba32"
                          data-id="f17ba32" data-element_type="column">
                          <div className="banner-img elementor-widget-wrap elementor-element-populated">
                            <Banners/>
                            {/* <div
                              className="elementor-element elementor-element-dc9815f elementor-widget elementor-widget-image"
                              data-id="dc9815f" data-element_type="widget" data-widget_type="image.default">
                              <div className="elementor-widget-container">
                                <img fetchpriority="high" decoding="async" width="700" height="850"
                                  src="images/banner.jpg"
                                  className="elementor-animation-float attachment-large size-large wp-image-183" alt=""/>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-c7ea855 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="c7ea855" data-element_type="section">
                      <div className="elementor-background-overlay"></div>
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4e01a78"
                          data-id="4e01a78" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-6ccd84e elementor-widget elementor-widget-image"
                              data-id="6ccd84e" data-element_type="widget" data-widget_type="image.default">
                              <div className="elementor-widget-container">
                                <img decoding="async" width="700" height="850" src="images/smfive.jpg"
                                  className="frame-img" alt=""/>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-a4ba463 uael-heading-align-left uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading"
                              data-id="a4ba463" data-element_type="widget"
                              data-widget_type="uael-advanced-heading.default">
                              <div className="elementor-widget-container">
                                <div className="uael-module-content uael-heading-wrapper">
                                  <h4 className="uael-heading">
                                    <span className="uael-heading-text elementor-inline-editing uael-size--default"
                                      data-elementor-setting-key="heading_title"
                                      data-elementor-inline-editing-toolbar="basic">Abstract Acrylic</span>
                                  </h4>
                                  <div className="uael-subheading elementor-inline-editing"
                                    data-elementor-setting-key="heading_description"
                                    data-elementor-inline-editing-toolbar="advanced">
                                    Duconvallis massa ultricies. Nulla viverra rutrum dolor, at varius lectus ultrices id.
                                    Mauris ipsum eros, pulvinar eu elit luctus. </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5428724"
                          data-id="5428724" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-3ed3413 elementor-widget elementor-widget-uael-infobox"
                              data-id="3ed3413" data-element_type="widget" data-widget_type="uael-infobox.default">
                              <div className="elementor-widget-container">
                                <div
                                  className="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-button">
                                  <div className="uael-infobox-left-right-wrap">
                                    <div className="uael-infobox-content">
                                      <div className="uael-infobox-title-wrap">
                                        <h2 className="uael-infobox-title elementor-inline-editing"
                                          data-elementor-setting-key="infobox_title"
                                          data-elementor-inline-editing-toolbar="basic">Recent Artworks</h2>
                                      </div>
                                      <div className="uael-infobox-text-wrap">
                                        <div className="uael-infobox-text elementor-inline-editing"
                                          data-elementor-setting-key="infobox_description"
                                          data-elementor-inline-editing-toolbar="advanced">
                                          Vestibulum mollis nisi at mauris accumsan dapibus. In dictum dui quis sem
                                          ullamcorper, eu convallis massa ultricies. Nulla viverra rutrum dolor, at varius
                                          lectus ultrices id. Mauris ipsum eros, pulvinar eu elit luctus, cursus mattis
                                          libero. </div>
                                        <div className="uael-button-wrapper elementor-button-wrapper">
                                          <a href="#" className="elementor-button-link  elementor-button elementor-size-sm">
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-align-icon-right elementor-button-icon">
                                              </span>
                                              <span className="elementor-button-text elementor-inline-editing"
                                                data-elementor-setting-key="infobox_button_text"
                                                data-elementor-inline-editing-toolbar="none">See Gallery</span>
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-caba724 elementor-widget elementor-widget-image"
                              data-id="caba724" data-element_type="widget" data-widget_type="image.default">
                              <div className="elementor-widget-container">
                                <img decoding="async" width="850" height="700" src="img/art1.jpg"
                                  className="frame-img" alt=""/>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-e7084f6 uael-heading-align-left uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading"
                              data-id="e7084f6" data-element_type="widget"
                              data-widget_type="uael-advanced-heading.default">
                              <div className="elementor-widget-container">
                                <div className="uael-module-content uael-heading-wrapper">
                                  <h4 className="uael-heading">
                                    <span className="uael-heading-text elementor-inline-editing uael-size--default"
                                      data-elementor-setting-key="heading_title"
                                      data-elementor-inline-editing-toolbar="basic">Abstract Artwork</span>
                                  </h4>
                                  <div className="uael-subheading elementor-inline-editing"
                                    data-elementor-setting-key="heading_description"
                                    data-elementor-inline-editing-toolbar="advanced">
                                    Duconvallis massa ultricies. Nulla viverra rutrum dolor, at varius lectus ultrices id.
                                    Mauris ipsum eros, pulvinar eu elit luctus. </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section background-section-img elementor-top-section elementor-element elementor-element-1223c64 elementor-section-boxed elementor-section-height-default elementor-section-height-default" style={{backgroundImage:"url('images/artist-intro-bg.jpg')"}}
                      data-id="1223c64" data-element_type="section">
                      <div className="elementor-background-overlay"></div>
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-ab5c6cb"
                          data-id="ab5c6cb" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-a9b8dd4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="a9b8dd4" data-element_type="section">
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1e35e71"
                                  data-id="1e35e71" data-element_type="column">
                                  <div className="elementor-widget-wrap elementor-element-populated" style={{backgroundImage:"url('images/artist_vikram.jpg')"}}>
                                    <div
                                      className="elementor-element elementor-element-dc5a881 elementor-widget elementor-widget-spacer"
                                      data-id="dc5a881" data-element_type="widget" data-widget_type="spacer.default">
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-554f50c"
                                  data-id="554f50c" data-element_type="column">
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-7f1f1cb elementor-hidden-phone elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="7f1f1cb" data-element_type="widget" data-widget_type="divider.default">
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator">
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-6c58527 elementor-widget elementor-widget-uael-infobox"
                                      data-id="6c58527" data-element_type="widget"
                                      data-widget_type="uael-infobox.default">
                                      <div className="elementor-widget-container">
                                        <div
                                          className="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-link">
                                          <div className="uael-infobox-left-right-wrap">
                                            <div className="uael-infobox-content">
                                              <div className="uael-infobox-title-wrap">
                                                <h3 className="uael-infobox-title elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title"
                                                  data-elementor-inline-editing-toolbar="basic">My Story</h3>
                                              </div>
                                              <div className="uael-infobox-text-wrap">
                                                <div className="uael-infobox-text elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_description"
                                                  data-elementor-inline-editing-toolbar="advanced">
                                                  Imperdiet lacus sit amet scelerisque commodo. Praesent nec lectus ante.
                                                  Nulla ultrices ligula vitae pellentesque ullamcorper. Quisque
                                                  scelerisque varius mattis. Integer ac libero congue, fermentum odio vel,
                                                  posuere est. Morbi neque elit, pulvinar eu velit non, maximus bibendum
                                                  arcu. Ut sollicitudin ante sed aliquam lobortis. Curabitur gravida,
                                                  ipsum ut malesuada congue, metus tellus ornare dui, eget interdum arcu
                                                  nisi id elit. </div>
                                                <div className="uael-infobox-cta-link-style">
                                                <NavLink to="/about"
                                            className={"uael-infobox-cta-link"}>Read More</NavLink>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-d1b7bfc"
                          data-id="d1b7bfc" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-f0b374d elementor-widget elementor-widget-text-editor"
                              data-id="f0b374d" data-element_type="widget" data-widget_type="text-editor.default">
                              <div className="elementor-widget-container">
                                <p>“Donec non ex ultricies, luctus magna ac, auctor felis. Nam ornare, orci vel dapibus
                                  dapibus, orci justo lacinia dui, viverra felis neque at sapien.”</p>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-6689103 elementor-widget elementor-widget-image"
                              data-id="6689103" data-element_type="widget" data-widget_type="image.default">
                              <div className="elementor-widget-container">
                                <img loading="lazy" decoding="async" width="138" height="41"
                                  src="images/artist-sign-free-img.png" className="attachment-large size-large wp-image-89"
                                  alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-4c09d95 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-71e79a6"
                          data-id="71e79a6" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-b20f91a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="b20f91a" data-element_type="section">
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f7986d6"
                                  data-id="f7986d6" data-element_type="column">
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-d5c5744 uael-heading-align-right uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading"
                                      data-id="d5c5744" data-element_type="widget"
                                      data-widget_type="uael-advanced-heading.default">
                                      <div className="elementor-widget-container">
                                        <div className="uael-module-content uael-heading-wrapper">
                                          <h2 className="uael-heading">
                                            <span className="uael-heading-text elementor-inline-editing uael-size--default"
                                              data-elementor-setting-key="heading_title"
                                              data-elementor-inline-editing-toolbar="basic">Exhibitions</span>
                                          </h2>
                                          <div className="uael-subheading elementor-inline-editing"
                                            data-elementor-setting-key="heading_description"
                                            data-elementor-inline-editing-toolbar="advanced">
                                            Vestibulum mollis nisi at mauris accumsan dapibus. In dictum dui quis sem
                                            ullamcorper, eu convallis massa ultricies. Nulla viverra rutrum dolor, at
                                            varius lectus ultrices id. Mauris ipsum eros, pulvinar eu elit luctus, cursus
                                            mattis libero. Nulla eget felis elementum, semper purus eu, imperdiet leo.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69ee285"
                                  data-id="69ee285" data-element_type="column">
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-4117e72 elementor-widget elementor-widget-uael-infobox"
                                      data-id="4117e72" data-element_type="widget"
                                      data-widget_type="uael-infobox.default">
                                      <div className="elementor-widget-container">
                                        <div
                                          className="uael-module-content uael-infobox uael-imgicon-style-normal  uael-infobox-center  infobox-has-photo uael-infobox-photo-above-title  uael-infobox-link-type-none">
                                          <div className="uael-infobox-left-right-wrap">
                                            <div className="uael-infobox-content">
                                              <div className="uael-module-content uael-imgicon-wrap ">
                                                <div className="uael-image" itemscope=""
                                                  itemtype="http://schema.org/ImageObject">
                                                  <div className="uael-image-content elementor-animation-float ">
                                                    <img loading="lazy" decoding="async" width="850" height="700"
                                                      src="img/misl.jpg"
                                                      className="frame-img" alt=""/>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uael-infobox-title-wrap">
                                                <p className="uael-infobox-title-prefix elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title_prefix"
                                                  data-elementor-inline-editing-toolbar="basic">10 Jun 2018 - 20 Jun 2018
                                                </p>
                                                <h4 className="uael-infobox-title elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title"
                                                  data-elementor-inline-editing-toolbar="basic">Italian Artist Mega Meet
                                                </h4>
                                              </div>
                                              <div className="uael-infobox-text-wrap">
                                                <div className="uael-infobox-text elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_description"
                                                  data-elementor-inline-editing-toolbar="advanced">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-9097030 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="9097030" data-element_type="section">
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-dc4bc25"
                                  data-id="dc4bc25" data-element_type="column">
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-1af9e8d elementor-widget elementor-widget-uael-infobox"
                                      data-id="1af9e8d" data-element_type="widget"
                                      data-widget_type="uael-infobox.default">
                                      <div className="elementor-widget-container">
                                        <div
                                          className="uael-module-content uael-infobox uael-imgicon-style-normal  uael-infobox-center  infobox-has-photo uael-infobox-photo-above-title  uael-infobox-link-type-none">
                                          <div className="uael-infobox-left-right-wrap">
                                            <div className="uael-infobox-content">
                                              <div className="uael-module-content uael-imgicon-wrap ">
                                                <div className="uael-image" itemscope=""
                                                  itemtype="http://schema.org/ImageObject">
                                                  <div className="uael-image-content elementor-animation-float ">
                                                    <img loading="lazy" decoding="async" width="700" height="850"
                                                      src="img/girl.jpg"
                                                      className="frame-img" alt=""/>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uael-infobox-title-wrap">
                                                <p className="uael-infobox-title-prefix elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title_prefix"
                                                  data-elementor-inline-editing-toolbar="basic">02 May 2018 - 10 May 2018
                                                </p>
                                                <h4 className="uael-infobox-title elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title"
                                                  data-elementor-inline-editing-toolbar="basic">Vegas Art Exhibition</h4>
                                              </div>
                                              <div className="uael-infobox-text-wrap">
                                                <div className="uael-infobox-text elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_description"
                                                  data-elementor-inline-editing-toolbar="advanced">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d14c1b7"
                                  data-id="d14c1b7" data-element_type="column">
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-7a55c32 elementor-widget elementor-widget-uael-infobox"
                                      data-id="7a55c32" data-element_type="widget"
                                      data-widget_type="uael-infobox.default">
                                      <div className="elementor-widget-container">
                                        <div
                                          className="uael-module-content uael-infobox uael-imgicon-style-normal  uael-infobox-center  infobox-has-photo uael-infobox-photo-above-title  uael-infobox-link-type-none">
                                          <div className="uael-infobox-left-right-wrap">
                                            <div className="uael-infobox-content">
                                              <div className="uael-module-content uael-imgicon-wrap ">
                                                <div className="uael-image" itemscope=""
                                                  itemtype="http://schema.org/ImageObject">
                                                  <div className="uael-image-content elementor-animation-float ">
                                                    <img loading="lazy" decoding="async" width="700" height="850"
                                                      src="img/art2.jpg"
                                                      className="frame-img" alt=""/>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uael-infobox-title-wrap">
                                                <p className="uael-infobox-title-prefix elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title_prefix"
                                                  data-elementor-inline-editing-toolbar="basic">12 Apr 2018 - 20 Apr 2018
                                                </p>
                                                <h4 className="uael-infobox-title elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title"
                                                  data-elementor-inline-editing-toolbar="basic">Lyon Mega Exhibition</h4>
                                              </div>
                                              <div className="uael-infobox-text-wrap">
                                                <div className="uael-infobox-text elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_description"
                                                  data-elementor-inline-editing-toolbar="advanced">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6979e0b"
                                  data-id="6979e0b" data-element_type="column">
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-033b28f elementor-widget elementor-widget-uael-infobox"
                                      data-id="033b28f" data-element_type="widget"
                                      data-widget_type="uael-infobox.default">
                                      <div className="elementor-widget-container">
                                        <div
                                          className="uael-module-content uael-infobox uael-imgicon-style-normal  uael-infobox-center  infobox-has-photo uael-infobox-photo-above-title  uael-infobox-link-type-none">
                                          <div className="uael-infobox-left-right-wrap">
                                            <div className="uael-infobox-content">
                                              <div className="uael-module-content uael-imgicon-wrap ">
                                                <div className="uael-image" itemscope=""
                                                  itemtype="http://schema.org/ImageObject">
                                                  <div className="uael-image-content elementor-animation-float ">
                                                    <img loading="lazy" decoding="async" width="700" height="850"
                                                      src="img/art4.jpg"
                                                      className="frame-img" alt=""/>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uael-infobox-title-wrap">
                                                <p className="uael-infobox-title-prefix elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title_prefix"
                                                  data-elementor-inline-editing-toolbar="basic">20 Feb 2018 - 01 Mar 2018
                                                </p>
                                                <h4 className="uael-infobox-title elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_title"
                                                  data-elementor-inline-editing-toolbar="basic">Paris Painting Exhibition
                                                </h4>
                                              </div>
                                              <div className="uael-infobox-text-wrap">
                                                <div className="uael-infobox-text elementor-inline-editing"
                                                  data-elementor-setting-key="infobox_description"
                                                  data-elementor-inline-editing-toolbar="advanced">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    )
}

export default HomePage
