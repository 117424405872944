import React, {useState} from 'react';
import { NavLink } from "react-router-dom";
import './Headers.scss'

function Header() {
    const [isOpenNav, setIsOpenNav] = useState(false)
    const setActive = ({ isActive }) => isActive ? "active menu-link" : "menu-link";
    const toggleMobileNav = () => {
        setIsOpenNav(!isOpenNav)
    };
    
    return (
        <header
            className="site-header header-main-layout-1 ast-primary-menu-enabled ast-hide-custom-menu-mobile ast-builder-menu-toggle-icon ast-mobile-header-inline"
            id="masthead">
            <div id="arts-header">
                <div className="ast-main-header-wrap main-header-bar-wrap ">
                    <div className="ast-primary-header-bar ast-primary-header main-header-bar site-header-focus-item"
                        data-section="section-primary-header-builder">
                        <div className="site-primary-header-wrap ast-builder-grid-row-container site-header-focus-item ast-container"
                            data-section="section-primary-header-builder">
                            <div className="ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center">
                                <div className="site-header-primary-section-left site-header-section ast-flex site-header-section-left">
                                    <div className="ast-builder-layout-element ast-flex site-header-focus-item" data-section="title_tagline">
                                        <div className="site-branding ast-site-identity">
                                            <span className="site-logo-img">
                                                <NavLink to="/" className="custom-logo-link">
                                                    <img width="221" height="61" src="images/artsmaniac_logo.svg" className="custom-logo astra-logo-svg" alt="Artist" decoding="async" />
                                            artsmaniac
                                        </NavLink>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div className="site-header-primary-section-right site-header-section ast-flex ast-grid-right-section">
                                    
                                    <div className="ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item"
                                        data-section="section-hb-menu-1">
                                        <div className="ast-button-mobile">
                                            <button className="menu-toggle main-header-menu-toggle ast-mobile-menu-trigger-fill" onClick={toggleMobileNav}>
											<span className="screen-reader-text">Main Menu</span>
											<span className="mobile-menu-toggle-icon">
												<span className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg
														className="ast-mobile-svg ast-menu-svg" fill="currentColor"
														version="1.1" xmlns="http://www.w3.org/2000/svg" width="24"
														height="24" viewBox="0 0 24 24">
														<path
															d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z">
														</path>
													</svg></span><span
													className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg
														className="ast-mobile-svg ast-close-svg" fill="currentColor"
														version="1.1" xmlns="http://www.w3.org/2000/svg" width="24"
														height="24" viewBox="0 0 24 24">
														<path
															d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z">
														</path>
													</svg></span> </span>
										</button>
									</div>
                                        <div className={`ast-main-header-bar-alignment ${isOpenNav?'openNav':''}`}>
                                            <div className="main-header-bar-navigation">
                                                <nav className="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item"
                                                    id="primary-site-navigation-desktop" aria-label="Site Navigation: Primary Manu">
                                                    <div className="main-navigation ast-inline-flex">
                                                        <ul id="ast-hf-menu-1"
                                                            className="main-header-menu ast-menu-shadow ast-nav-menu ast-flex  submenu-with-border stack-on-mobile">
                                                            <li id="menu-item-16"
                                                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-16">
                                                                <NavLink to="/"
                                                                    className={setActive}>Home</NavLink></li>
                                                            <li id="menu-item-17"
                                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17"><NavLink className={setActive} to="/portfolio">Portfolio</NavLink>
                                                            </li>
                                                            <li id="menu-item-18"
                                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18"><NavLink className={setActive} to="/about">About</NavLink></li>
                                                            <li id="menu-item-20"
                                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20"><NavLink className={setActive} to="/contact">Contact</NavLink></li>
                                                            <li id="menu-item-20"
                                                                className="phone-number menu-item menu-item-type-post_type menu-item-object-page menu-item-20"><a
                                                                    href="/" className={setActive}>+91 - 9818468295</a></li>
                                                        </ul>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
