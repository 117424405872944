import React from 'react'
import './Footer.scss';


function Footer() {
  // http://localhost:8080/api/emailed
    return (
      <footer className="site-footer" id="colophon">
      <div className="site-primary-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-3-equal ast-builder-grid-row-tablet-3-equal ast-builder-grid-row-mobile-full ast-footer-row-stack ast-footer-row-tablet-stack ast-footer-row-mobile-stack"
        data-section="section-primary-footer-builder">
        <div className="ast-builder-grid-row-container-inner">
          <div className="ast-builder-footer-grid-columns site-primary-footer-inner-wrap ast-builder-grid-row">
            <div className="site-footer-primary-section-1 site-footer-section site-footer-section-1">
              <aside className="footer-widget-area widget-area site-footer-focus-item footer-widget-area-inner"
                data-section="sidebar-widgets-footer-widget-1" aria-label="Footer Widget 1">
                <section id="text-3" className="widget widget_text">
                  <h2 className="widget-title">Contact</h2>
                  <div className="textwidget">
                    <p>+91 9818468295</p>
                  </div>
                </section>
              </aside>
            </div>
            <div className="site-footer-primary-section-2 site-footer-section site-footer-section-2">
              <aside className="footer-widget-area widget-area site-footer-focus-item footer-widget-area-inner"
                data-section="sidebar-widgets-footer-widget-2" aria-label="Footer Widget 2">
                <section id="text-4" className="widget widget_text">
                  <h2 className="widget-title">Location</h2>
                  <div className="textwidget">
                    <p>New Delhi, India</p>
                  </div>
                </section>
              </aside>
            </div>
            <div className="site-footer-primary-section-3 site-footer-section site-footer-section-3">
              <aside className="footer-widget-area widget-area site-footer-focus-item footer-widget-area-inner"
                data-section="sidebar-widgets-footer-widget-3" aria-label="Footer Widget 3">
                <section id="text-5" className="widget widget_text">
                  <h2 className="widget-title">Follow</h2>
                  <div className="textwidget"></div>
                </section>
              </aside>
              <div className="ast-builder-layout-element ast-flex site-footer-focus-item"
                data-section="section-fb-social-icons-1">
                <div className="ast-footer-social-1-wrap ast-footer-social-wrap">
                  <div
                    className="footer-social-inner-wrap element-social-inner-wrap social-show-label-false ast-social-color-type-custom ast-social-stack-none ast-social-element-style-filled">
                    <a href="" aria-label="Facebook" target="_blank" rel="noopener noreferrer"
                      className="ast-builder-social-element ast-inline-flex ast-facebook footer-social-item"><span
                        className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512">
                          <path
                            d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z">
                          </path>
                        </svg></span></a><a href="" aria-label="Instagram" target="_blank" rel="noopener noreferrer"
                      className="ast-builder-social-element ast-inline-flex ast-instagram footer-social-item"><span
                        className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512">
                          <path
                            d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z">
                          </path>
                        </svg></span></a><a href="" aria-label="Dribbble" target="_blank" rel="noopener noreferrer"
                      className="ast-builder-social-element ast-inline-flex ast-dribbble footer-social-item"><span
                        className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512">
                          <path
                            d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z">
                          </path>
                        </svg></span></a><a href="" aria-label="Behance" target="_blank" rel="noopener noreferrer"
                      className="ast-builder-social-element ast-inline-flex ast-behance footer-social-item"><span
                        className="ahfb-svg-iconset ast-inline-flex svg-baseline"><svg xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512">
                          <path
                            d="M232 237.2c31.8-15.2 48.4-38.2 48.4-74 0-70.6-52.6-87.8-113.3-87.8H0v354.4h171.8c64.4 0 124.9-30.9 124.9-102.9 0-44.5-21.1-77.4-64.7-89.7zM77.9 135.9H151c28.1 0 53.4 7.9 53.4 40.5 0 30.1-19.7 42.2-47.5 42.2h-79v-82.7zm83.3 233.7H77.9V272h84.9c34.3 0 56 14.3 56 50.6 0 35.8-25.9 47-57.6 47zm358.5-240.7H376V94h143.7v34.9zM576 305.2c0-75.9-44.4-139.2-124.9-139.2-78.2 0-131.3 58.8-131.3 135.8 0 79.9 50.3 134.7 131.3 134.7 61.3 0 101-27.6 120.1-86.3H509c-6.7 21.9-34.3 33.5-55.7 33.5-41.3 0-63-24.2-63-65.3h185.1c.3-4.2.6-8.7.6-13.2zM390.4 274c2.3-33.7 24.7-54.8 58.5-54.8 35.4 0 53.2 20.8 56.2 54.8H390.4z">
                          </path>
                        </svg></span></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="site-below-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-full ast-builder-grid-row-tablet-full ast-builder-grid-row-mobile-full ast-footer-row-inline ast-footer-row-tablet-inline ast-footer-row-mobile-inline"
        data-section="section-below-footer-builder">
        <div className="ast-builder-grid-row-container-inner">
          <div className="ast-builder-footer-grid-columns site-below-footer-inner-wrap ast-builder-grid-row">
            <div className="site-footer-below-section-1 site-footer-section site-footer-section-1">
              <div className="ast-builder-layout-element ast-flex site-footer-focus-item ast-footer-copyright"
                data-section="section-footer-builder">
                <div className="ast-footer-copyright">
                  <p>© 2024 Arts Maniac - All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    )
}

export default Footer
