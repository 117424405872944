import React from 'react'
import Slides from "../about/slides";
import './AboutPage.scss'

function AboutPage() {
  return (
    <div id="content" className="site-content">
      <div className="ast-container">
        <div id="primary" className="content-area primary">
          <main id="main" className="site-main">
            <article className="post-12 page type-page status-publish ast-article-single" id="post-12" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
              <div className="entry-content clear" itemprop="text">
                <div data-elementor-type="wp-post" data-elementor-id="12" className="elementor elementor-12">
                  <section className="rest-banner-section elementor-section elementor-section-boxed" data-id="e8fc428" data-element_type="section" style={{backgroundImage:"url('images/abstract-bg-free-img.jpg')"}}>
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0d6d74d" data-id="0d6d74d" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-c2e7c99 elementor-widget elementor-widget-heading" data-id="c2e7c99" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h1 className="elementor-heading-title elementor-size-default">My Story.</h1> </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7f110c2" data-id="7f110c2" data-element_type="column">
                        <div className="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-e2fb838 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e2fb838" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a95bcc4" data-id="a95bcc4" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-e924372 uael-heading-align-left uael-mobile-heading-align-center elementor-widget elementor-widget-uael-advanced-heading" data-id="e924372" data-element_type="widget" data-widget_type="uael-advanced-heading.default">
                            <div className="elementor-widget-container">
                              <div className="uael-module-content uael-heading-wrapper">
                                <h2 className="uael-heading">
                                  <span className="uael-heading-text elementor-inline-editing uael-size--default" data-elementor-setting-key="heading_title" data-elementor-inline-editing-toolbar="basic">Canvas is life..</span>
                                </h2>
                                <div className="uael-subheading elementor-inline-editing" data-elementor-setting-key="heading_description" data-elementor-inline-editing-toolbar="advanced">
                                  " Cras ultricies ligula sed magna dictum porta. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Donec rutrum congue leo eget malesuada. Cras ultricies ligula sed magna dictum porta. " </div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-fac2577 elementor-widget elementor-widget-text-editor" data-id="fac2577" data-element_type="widget" data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                              <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Donec sollicitudin molestie malesuada. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Donec sollicitudin molestie malesuada. Cras ultricies ligula sed magna dictum porta. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Donec rutrum congue leo eget malesuada. Cras ultricies ligula sed magna dictum porta.</p><p>Donec sollicitudin molestie malesuada. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Donec sollicitudin molestie malesuada. Cras ultricies ligula sed magna dictuam porta. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Donec rutrum congue leo eget malesuada. Cras ultricies ligula sed magna dictum porta.�&nbsp; Mauris blandit aliquet elit, eget tincidunt nibh pulvinar.</p> </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-31ad23d" data-id="31ad23d" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-442f2db elementor-widget elementor-widget-image" data-id="442f2db" data-element_type="widget" data-widget_type="image.default">
                            <div className="elementor-widget-container">
                              <img fetchpriority="high" decoding="async" width="700" height="900" src="images/artist-about.jpg" className="frame-img" alt="" /> </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-faa2786 elementor-section-boxed elementor-section-height-default elementor-section-height-default"  style={{backgroundImage:"url('images/artist-about-bg.jpg')"}}>
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a68deed" data-id="a68deed" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-fa3a5c8 elementor-widget elementor-widget-uael-infobox" data-id="fa3a5c8" data-element_type="widget" data-widget_type="uael-infobox.default">
                            <div className="elementor-widget-container">
                              <div className="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-none">
                                <div className="uael-infobox-left-right-wrap">
                                  <div className="uael-infobox-content">
                                    <div className="uael-infobox-title-wrap"><h3 className="uael-infobox-title-prefix elementor-inline-editing" data-elementor-setting-key="infobox_title_prefix" data-elementor-inline-editing-toolbar="basic">I am</h3><h2 className="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">Grace Jones</h2></div> <div className="uael-infobox-text-wrap">
                                      <div className="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                        Imperdiet lacus sit amet scelerisque commodo. Praesent nec lectus ante. Nulla ultrices ligula vitae pellentesque ullamcorper. Quisque scelerisque varius mattis. Integer ac libero congue, fermentum odio vel, posuere est. Morbi neque elit, pulvinar eu velit non, maximus bibendum arcu. Ut sollicitudin ante sed aliquam lobortis. Curabitur gravida, ipsum ut malesuada congue, metus tellus ornare dui, eget interdum arcu nisi id elit. </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-84cd3ea elementor-widget elementor-widget-image" data-id="84cd3ea" data-element_type="widget" data-widget_type="image.default">
                            <div className="elementor-widget-container">
                              <img decoding="async" width="138" height="41" src="images/artist-sign-free-img.png" className="attachment-large size-large wp-image-89" alt="" /> </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-aecbeb7" data-id="aecbeb7" data-element_type="column">
                        <div className="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-509002a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="509002a" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-ec21525" data-id="ec21525" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-b7661ca uael-ins-normal elementor-widget elementor-widget-uael-image-gallery" data-id="b7661ca" data-element_type="widget" data-widget_type="uael-image-gallery.default">
                            <div className="elementor-widget-container">
                              <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                <div className="thumbnail-carousel uael-img-gallery-wrap uael-img-carousel-wrap uael-nav-none" data-filter-default="All">
                                  <Slides/>
                                </div>
                            </div>
                          </div>

                          </div>
                        </div>
                      </div>
                      <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-eaed9f6" data-id="eaed9f6" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div className="elementor-element elementor-element-87fc296 elementor-widget elementor-widget-uael-infobox" data-id="87fc296" data-element_type="widget" data-widget_type="uael-infobox.default">
                            <div className="elementor-widget-container">
                              <div className="uael-module-content uael-infobox  uael-infobox-left  uael-infobox-link-type-button">
                                <div className="uael-infobox-left-right-wrap">
                                  <div className="uael-infobox-content">
                                    <div className="uael-infobox-title-wrap"><h3 className="uael-infobox-title elementor-inline-editing" data-elementor-setting-key="infobox_title" data-elementor-inline-editing-toolbar="basic">Popular Paintings</h3></div> <div className="uael-infobox-text-wrap">
                                      <div className="uael-infobox-text elementor-inline-editing" data-elementor-setting-key="infobox_description" data-elementor-inline-editing-toolbar="advanced">
                                        Duis dictum ipsum in quam pharetra, non porttitor lacus varius. Praesent euismod mauris vitae convallis pharetra. </div>
                                      <div className="uael-button-wrapper elementor-button-wrapper">
                                        <a href="#" className="elementor-button-link  elementor-button elementor-size-sm">
                                          <span className="elementor-button-content-wrapper">
                                            <span className="elementor-align-icon-right elementor-button-icon">
                                            </span>
                                            <span className="elementor-button-text elementor-inline-editing" data-elementor-setting-key="infobox_button_text" data-elementor-inline-editing-toolbar="none">See Gallery</span>
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

  )
}

export default AboutPage
