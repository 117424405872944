import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slides = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay:true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const photos = [
    {
      "imgUrl":"miscellaneous/artsmaniac_vikram_6.JPG"
    },
    {
      "imgUrl":"photos/artsmaniac_vikram_gal13.jpg"
    },
    {
      "imgUrl":"miscellaneous/artsmaniac_vikram_8.JPG"
    },
    {
      "imgUrl":"photos/artsmaniac_vikram_gal3.jpg"
    },
    {
      "imgUrl":"miscellaneous/artsmaniac_vikram_9.JPG"
    },
    {
      "imgUrl":"miscellaneous/artsmaniac_vikram_7.JPG"
    }
  ]

  return (
    <Slider {...settings}>
        {photos && photos.map((item, index) => 
          <div key={index}>
            <img src={`img/${item.imgUrl}`} className="thumb-frame-img"/>
            {/* <div className="banner_content">
              <h3 className="heading">{item.heading}</h3>
              <div className="description">{item.description}</div>
            </div> */}
        </div>
        )}
    </Slider>
    )
}

export default Slides