import { useEffect, useState } from "react";
import axios from 'axios';
import { BASE_URL, BANNERS_ENDPOINT } from "../constant/Constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banners = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows:false,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
  };
  const photos = [
    {
      "imgUrl":"art1.jpg"
    },
    {
      "imgUrl":"art2.jpg"
    },
    {
      "imgUrl":"art3.jpg"
    }
  ]

  return (
    <Slider {...settings}>
        {photos && photos.map((item, index) => 
          <div key={index}>
            <img src={`images/${item.imgUrl}`}/>
            {/* <div className="banner_content">
              <h3 className="heading">{item.heading}</h3>
              <div className="description">{item.description}</div>
            </div> */}
        </div>
        )}
    </Slider>
    )
}

export default Banners